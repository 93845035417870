import { lazy } from "react";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";
import { FEATURE_FLAGS } from "../feature-flags";
import { ReactComponent as AdminIcon } from "icons/admin_management.svg";

const AddProjectData = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AddProjectDataForm' */ "pages/ProjectData/AddProjectDataForm"
    )
  )
);

const TimesheetList = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Timesheet/TimesheetList")
  )
);

const ProjectData = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectData' */ "pages/ProjectData/ProjectDataListing"
    )
  )
);

const EditProjectData = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditProjectDataForm' */ "pages/ProjectData/EditProjectDataForm"
    )
  )
);

// const ProjectDataClientConfiguration = lazy(() =>
//   lazyRetry(() =>
//     import(
//       /* webpackChunkName: 'EditProjectDataForm' */ "pages/ProjectData/ClientConfiguration"
//     )
//   )
// );

const ClientOrganizationConfiguration = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditProjectDataForm' */ "pages/ProjectData/ClientConfiguration/ClientOrganization"
    )
  )
);

const BudgetTracking = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'BudgetTracking' */ "pages/ProjectData/BudgetTracking"
    )
  )
);

const PurchaseOrders = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'BudgetTracking' */ "pages/ProjectData/ProjectDataPurchaseOrderListing"
    )
  )
);

const TimeEntryReports = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'TimeEntryReports' */ "pages/Timesheet/TimeEntryReports"
    )
  )
);

const AdministrationSetting = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'TimeEntryReports' */ "pages/AdministrationSetting"
    )
  )
);

const TimeEntry = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Timesheet/TimeEntry")
  )
);

const InvoiceList = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: 'SignIn' */ "pages/InvoiceForm/InvoiceList"))
);

const AddInvoice = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/InvoiceForm/AddInvoice")
  )
);

const EditInvoice = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/InvoiceForm/EditInvoice")
  )
);

const ExpensesList = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Expenses/ExpensesList")
  )
);

const AddExpense = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Expenses/AddExpense")
  )
);

const EditExpense = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Expenses/EditExpense")
  )
);

const ExpenseReport = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Expenses/ExpnseReport")
  )
);

const administrationRoutes = Object.freeze({
  id: LINKS.ADMINISTRATION.TITLE,
  path: LINKS.ADMINISTRATION.HREF,
  icon: <AdminIcon />,
  component: null,
  roles: LINKS.ADMINISTRATION.ROLES,
  children: [
    {
      path: LINKS.PROJECT_DATA.HREF,
      name: LINKS.PROJECT_DATA.TITLE,
      component: ProjectData,
      feature: FEATURE_FLAGS.ProjectData,
      roles: LINKS.PROJECT_DATA.ROLES,
    },
    {
      path: LINKS.CLIENTS_ORGANIZATION.HREF,
      name: LINKS.CLIENTS_ORGANIZATION.TITLE,
      component: ClientOrganizationConfiguration,
      feature: FEATURE_FLAGS.Clients,
    },
    {
      path: LINKS.BUDGET_TRACKING.HREF,
      name: LINKS.BUDGET_TRACKING.TITLE,
      component: BudgetTracking,
      feature: FEATURE_FLAGS.BudgetTracker,
    },
    {
      path: LINKS.ADD_PROJECT_DATA.HREF,
      name: LINKS.ADD_PROJECT_DATA.TITLE,
      component: AddProjectData,
      isNotSlide: true,
    },
    {
      path: LINKS.EDIT_PROJECT_DATA.HREF,
      name: LINKS.EDIT_PROJECT_DATA.TITLE,
      component: EditProjectData,
      isNotSlide: true,
    },
    // {
    //   path: LINKS.EDIT_ORGANIZATION_CLIENTS.HREF,
    //   name: LINKS.EDIT_ORGANIZATION_CLIENTS.TITLE,
    //   component: ClientOrganizationConfiguration,
    //   // isNotSlide: true,
    // },

    {
      path: LINKS.PURCHASE_ORDERS.HREF,
      name: LINKS.PURCHASE_ORDERS.TITLE,
      component: PurchaseOrders,
      feature: FEATURE_FLAGS.ProjectControlPurchaseOrder,
    },
    {
      path: LINKS.TIME_ENTRY_REPORTS.HREF,
      name: LINKS.TIME_ENTRY_REPORTS.TITLE,
      component: TimeEntryReports,
      feature: FEATURE_FLAGS.TimesheetReports,
    },
    {
      path: LINKS.TIMESHEET_PROJECT_CONTROL.HREF,
      name: LINKS.TIMESHEET_PROJECT_CONTROL.TITLE,
      component: TimesheetList,
      feature: FEATURE_FLAGS.TimesheetApprovals,
    },
    {
      path: LINKS.ADD_TIMESHEET_ENTRY_PROJECT_CONTROL.HREF,
      name: LINKS.ADD_TIMESHEET_ENTRY_PROJECT_CONTROL.TITLE,
      component: TimeEntry,
      isNotSlide: true,
    },
    {
      path: LINKS.INVOICE_TIMESHEET.HREF,
      name: LINKS.INVOICE_TIMESHEET.TITLE,
      component: TimeEntry,
      isNotSlide: true,
    },
    {
      path: LINKS.INVOICE_TIME_ENTRY.HREF,
      name: LINKS.INVOICE_TIME_ENTRY.TITLE,
      component: TimesheetList,
    },
    {
      path: LINKS.INVOICES.HREF,
      name: LINKS.INVOICES.TITLE,
      component: InvoiceList,
    },
    {
      path: LINKS.ADD_INVOICE.HREF,
      name: LINKS.ADD_INVOICE.TITLE,
      component: AddInvoice,
      isNotSlide: true,
    },
    {
      path: LINKS.EDIT_INVOICE.HREF,
      name: LINKS.EDIT_INVOICE.TITLE,
      component: EditInvoice,
      isNotSlide: true,
    },
    {
      path: LINKS.EXPENSES.HREF,
      name: LINKS.EXPENSES.TITLE,
      component: ExpensesList,
    },
    {
      path: LINKS.ADD_EXPENSE.HREF,
      name: LINKS.ADD_EXPENSE.TITLE,
      component: AddExpense,
      isNotSlide: true,
    },
    {
      path: LINKS.EDIT_EXPENSE.HREF,
      name: LINKS.EDIT_EXPENSE.TITLE,
      component: EditExpense,
      isNotSlide: true,
    },
    {
      path: LINKS.EXPENSE_REPORT.HREF,
      name: LINKS.EXPENSE_REPORT.TITLE,
      component: ExpenseReport,
    },
    {
      path: LINKS.ADMINISTRATION_SETTING.HREF,
      name: LINKS.ADMINISTRATION_SETTING.TITLE,
      component: AdministrationSetting,
    },
  ],
});

export default administrationRoutes;
