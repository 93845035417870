import { PERMISSION_TYPES } from "utils/constants";
import moment from "moment";
export const DEFAULT_START_DATE = "2024-09-01T00:00:00.000Z";

export const HOUR_MINUTE_REGEX = /^(\d+)h\s(\d+)m$/;
export const HOUR_REGEX = /^(\d+)h$/;
export const MINUTE_REGEX = /^(\d+)m$/;

export const OTHER_TIME_REGEX = {
  regex1: /^(\d+)\s(\d+)m$/,
  regex2: /^(\d+)h\s(\d+)$/,
  regex3: /^(\d+):(\d+)$/,
  regex4: /^(\d+).(\d+)$/,
  regex5: /^(\d+)\s(\d+)$/,
  regex6: /^(\d+)$/,
};

export const TIMESHEET_STATUS = {
  SAVED: "Saved",
  SUBMITTED: "Submitted",
  APPROVED: "Approved",
  REJECTED: "Rejected",
};

export const WORK_LOCATION_VALUES = {
  ONSITE: "Onsite",
  REMOTE: "Remote",
};

export const BILLABLE_VALUES = {
  YES: "Yes",
  NO: "No",
};

export const WORK_LOCATION = [
  { label: "Onsite", value: "ONSITE" },
  { label: "Remote", value: "REMOTE" },
];

export const LEGENDS = [
  {
    color: "#5BA300",
    label: "Approved",
  },
  {
    color: "#D32F2F",
    label: "Rejected",
  },
  {
    color: "#0953B9",
    label: "Submitted (pending approval)",
  },
  {
    color: "#676869",
    label: "Not submitted",
  },
  {
    color: "#D32F2F",
    text: "!",
    label: "Overtime",
  },
];

export const BILLABLE = [
  { label: "Yes", value: "YES" },
  { label: "No", value: "NO" },
];

export const TIME_ENTRY = {
  customer: "",
  serviceItem: "",
  billable: "",
  workLocation: "",
  hours: "",
  class: "",
};

export const ADD_TIME_ENTRY_DATA = {
  timeEntry: [],
  status: "",
  totalHours: "0h 0m",
  userNote: "",
};

export const VIEW_TYPE = {
  WEEK: "Week",
  MONTH: "Month",
};

export const DIRECTION = {
  LEFT: "LEFT",
  RIGHT: "RIGHT",
};

export const VIEW_TYPES = [VIEW_TYPE.WEEK, VIEW_TYPE.MONTH];

export const TAB_LIST_TYPE = {
  ALL: "All",
  PENDING_ACTION: "Pending action",
  DRAFT: "Draft",
  SUBMITTED: "Submitted",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  PENDING: "Pending",
};

export const TAB_LIST_ADMIN = [
  TAB_LIST_TYPE.ALL,
  TAB_LIST_TYPE.PENDING_ACTION,
  TAB_LIST_TYPE.APPROVED,
  TAB_LIST_TYPE.REJECTED,
];
export const TAB_LIST_USER = [
  TAB_LIST_TYPE.ALL,
  TAB_LIST_TYPE.DRAFT,
  TAB_LIST_TYPE.SUBMITTED,
  TAB_LIST_TYPE.APPROVED,
  TAB_LIST_TYPE.REJECTED,
];

export const checkHourMinuteRegex = (value) => {
  return (
    HOUR_MINUTE_REGEX.test(value) ||
    HOUR_REGEX.test(value) ||
    MINUTE_REGEX.test(value)
  );
};

export const checkTimeformat = (value) => {
  return (
    OTHER_TIME_REGEX.regex1.test(value) ||
    OTHER_TIME_REGEX.regex2.test(value) ||
    OTHER_TIME_REGEX.regex3.test(value) ||
    OTHER_TIME_REGEX.regex4.test(value) ||
    OTHER_TIME_REGEX.regex5.test(value) ||
    OTHER_TIME_REGEX.regex6.test(value)
  );
};

export const getStartAndEndDateTimesheet = (type, date) => {
  let startDate = new Date();
  let endDate = new Date();
  if (type === VIEW_TYPE.WEEK) {
    const sub = new Date(date).getDay();

    startDate = new Date(
      new Date(date).setDate(date.getDate() - (sub ? sub : 7) + 1)
    );

    endDate = new Date(
      new Date(date).setDate(date.getDate() + 7 - (sub ? sub : 7))
    );
  } else if (type === VIEW_TYPE.MONTH) {
    const givenDate = new Date(date);
    startDate = new Date(givenDate.getFullYear(), givenDate.getMonth(), 1);
    endDate = new Date(givenDate.getFullYear(), givenDate.getMonth() + 1, 0);
  }

  return { startDate, endDate };
};

export const returnCorrectTimeFormat = (value) => {
  let newTime = "";
  if (OTHER_TIME_REGEX.regex1.test(value)) {
    const match = value.match(OTHER_TIME_REGEX.regex1);
    newTime = getFormattedByMinutesValue(
      parseInt(match[1]) * 60 + (match[2] ? parseInt(match[2]) : 0)
    );
    // newTime = `${parseInt(match[1])}h ${parseInt(match[2])}m`;
  } else if (OTHER_TIME_REGEX.regex2.test(value)) {
    const match = value.match(OTHER_TIME_REGEX.regex2);
    newTime = getFormattedByMinutesValue(
      parseInt(match[1]) * 60 + (match[2] ? parseInt(match[2]) : 0)
    );
    // newTime = `${parseInt(match[1])}h ${parseInt(match[2])}m`;
  } else if (OTHER_TIME_REGEX.regex3.test(value)) {
    const match = value.match(OTHER_TIME_REGEX.regex3);
    newTime = getFormattedByMinutesValue(
      parseInt(match[1]) * 60 + (match[2] ? parseInt(match[2]) : 0)
    );
    // newTime = `${parseInt(match[1])}h ${parseInt(match[2])}m`;
  } else if (OTHER_TIME_REGEX.regex4.test(value)) {
    const match = value.match(OTHER_TIME_REGEX.regex4);
    newTime = getFormattedByMinutesValue(
      parseInt(match[1]) * 60 + (match[2] ? parseInt(match[2]) : 0)
    );
    // newTime = `${parseInt(match[1])}h ${parseInt(match[2])}m`;
  } else if (OTHER_TIME_REGEX.regex5.test(value)) {
    const match = value.match(OTHER_TIME_REGEX.regex5);
    newTime = getFormattedByMinutesValue(
      parseInt(match[1]) * 60 + (match[2] ? parseInt(match[2]) : 0)
    );
    // newTime = `${parseInt(match[1])}h ${parseInt(match[2])}m`;
  } else if (OTHER_TIME_REGEX.regex6.test(value)) {
    const match = value.match(OTHER_TIME_REGEX.regex6);
    newTime = `${parseInt(match[1])}h`;
  } else {
    newTime = value;
  }
  return newTime;
};

export const getFormattedByMinutesValue = (sumTotalMinutes) => {
  return `${Math.floor(sumTotalMinutes / 60)}h ${sumTotalMinutes % 60}m`;
};

export const getFormattedByStringValue = (timeString) => {
  let tempTimeString = timeString;
  if (checkHourMinuteRegex(timeString)) {
    const validTime = tempTimeString?.trim().match(HOUR_MINUTE_REGEX)
      ? tempTimeString?.trim()
      : tempTimeString?.match(HOUR_REGEX)
      ? `${tempTimeString?.trim()} 0m`
      : tempTimeString?.match(MINUTE_REGEX)
      ? `0h ${tempTimeString?.trim()}`
      : null;
    if (validTime) {
      const match = validTime.match(HOUR_MINUTE_REGEX);
      if (match) {
        const hours = parseInt(match[1]);
        const minutes = match[2] ? parseInt(match[2]) : 0;
        const totalMinutes = hours * 60 + minutes;
        tempTimeString = Math.floor(totalMinutes / 60)
          ? `${Math.floor(totalMinutes / 60)}h ${totalMinutes % 60}m`
          : `${totalMinutes % 60}m`;
      }
    }
  }
  return tempTimeString;
};

export const addTimeSheetHoursArray = (timeArray) => {
  let totalMinutes = 0;
  timeArray?.forEach((time) => {
    const validTime = time?.trim().match(HOUR_MINUTE_REGEX)
      ? time?.trim()
      : time?.match(HOUR_REGEX)
      ? `${time?.trim()} 0m`
      : time?.match(MINUTE_REGEX)
      ? `0h ${time?.trim()}`
      : null;
    if (validTime) {
      const match = validTime.match(HOUR_MINUTE_REGEX);
      if (match) {
        const hours = parseInt(match[1]);
        const minutes = match[2] ? parseInt(match[2]) : 0;
        totalMinutes += hours * 60 + minutes;
      }
    }
  });
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h ${minutes}m`;
};

export const getTotalMinutes = (timeString) => {
  let totalMinutes = 0;
  if (checkHourMinuteRegex(timeString)) {
    const validTime = timeString?.trim().match(HOUR_MINUTE_REGEX)
      ? timeString?.trim()
      : timeString?.match(HOUR_REGEX)
      ? `${timeString?.trim()} 0m`
      : timeString?.match(MINUTE_REGEX)
      ? `0h ${timeString?.trim()}`
      : null;
    if (validTime) {
      const match = validTime.match(HOUR_MINUTE_REGEX);
      if (match) {
        const hours = parseInt(match[1]);
        const minutes = match[2] ? parseInt(match[2]) : 0;
        totalMinutes = hours * 60 + minutes;
      }
    }
  }
  return totalMinutes;
};

export const HourDiv = ({ data }) => {
  return (
    <p
      className="week-hour-box fw-bold"
      style={{
        borderColor: data?.id
          ? getTimeEntryBorderColor(data.status)
          : "transparent",
        borderStyle: data.status && data.totalHours && data?.id ? "solid" : "",
      }}
    >
      {data.totalHours}{" "}
      {getTotalMinutes(data.totalHours) > 480 ||
      (data?.date &&
        getTotalMinutes(data.totalHours) &&
        (moment(data?.date?.split("T")[0]).day() === 0 ||
          moment(data?.date?.split("T")[0]).day() === 6)) ? (
        <span className="red-exclamation fw-bold">!</span>
      ) : (
        ""
      )}
    </p>
  );
};

export const isSubOrApp = (
  curStatus,
  compareStatus = [
    TIMESHEET_STATUS.SUBMITTED.toUpperCase(),
    TIMESHEET_STATUS.APPROVED.toUpperCase(),
  ]
) => {
  return compareStatus?.includes(curStatus);
};

export const isNonEditable = (
  isHrActive,
  permission,
  curStatus,
  adminCompareStatus = [
    TIMESHEET_STATUS.APPROVED.toUpperCase(),
    TIMESHEET_STATUS.REJECTED.toUpperCase(),
  ],
  userCompareStatus = [
    TIMESHEET_STATUS.APPROVED.toUpperCase(),
    TIMESHEET_STATUS.SUBMITTED.toUpperCase(),
  ]
) => {
  return [PERMISSION_TYPES.superAdmin, PERMISSION_TYPES.admin].includes(
    permission
  ) || isHrActive
    ? adminCompareStatus?.includes(curStatus)
    : userCompareStatus?.includes(curStatus);
};

export const getTimeEntryBorderColor = (timeEntryStatus) => {
  switch (timeEntryStatus) {
    case TIMESHEET_STATUS.SAVED.toUpperCase():
      return "#79747E";
    case TIMESHEET_STATUS.SUBMITTED.toUpperCase():
      return "#0953B9";
    case TIMESHEET_STATUS.APPROVED.toUpperCase():
      return "#89CE00";
    case TIMESHEET_STATUS.REJECTED.toUpperCase():
      return "#D32F2F";
    default:
      return "white";
  }
};

export const getDateRangeOvertimeHour = (startDate, endDate) => {
  let start = moment(startDate?.split("T")[0]);
  let end = moment(endDate?.split("T")[0]);
  let count = 0;
  while (start.isSameOrBefore(end)) {
    const dayOfWeek = start.day();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      count++;
    }
    start.add(1, "days");
  }
  return count * 8;
};
